var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal",attrs:{"id":"modal","cancel-variant":"outline-secondary","ok-title":"Lưu","ok-only":"","centered":"","title":"Chỉnh sửa","ok-disabled":false,"no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-observer',{ref:"formRules"},[_c('b-form',[_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'name',"text":'Tên',"required":true}}),_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.input.name),callback:function ($$v) {_vm.$set(_vm.input, "name", $$v)},expression:"input.name"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1),_c('b-form-group',[_c('custom-label',{attrs:{"forLabel":'settingValue',"text":'Giá trị',"required":true}}),_c('validation-provider',{attrs:{"name":"settingValue","rules":_vm.input.type !== _vm.SETTING_TYPE_ENUM.Image ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failedRules = ref.failedRules;
return [(_vm.input.type === _vm.SETTING_TYPE_ENUM.Image)?[_c('b-form-file',{attrs:{"value":_vm.input.image,"state":errors.length > 0 ? false : null,"name":"settingValue","accept":"image/*"},on:{"change":_vm.imageChange}}),_c('b-img',{staticClass:"mt-2 w-100",attrs:{"thumbnail":"","src":_vm.input.image
                    ? _vm.URL.createObjectURL(_vm.input.image)
                    : (_vm.VUE_APP_API_BASE_HOST + "/" + (_vm.setting.settingValue))}})]:(_vm.input.type === _vm.SETTING_TYPE_ENUM.JsonObject)?[_vm._l((_vm.settingValueObject),function(item,index){return [[_c('div',{key:index,staticClass:"mb-2"},[_vm._l((Object.keys(item)),function(itemKey,itemIndex){return [[_c('div',{key:itemIndex},[_c('label',{attrs:{"for":"settingValue"}},[_vm._v(" "+_vm._s(_vm.$t(itemKey) + ' ' + (index + 1))+" ")]),_c('b-form-input',{attrs:{"id":"settingValue","trim":""},model:{value:(item[itemKey]),callback:function ($$v) {_vm.$set(item, itemKey, $$v)},expression:"item[itemKey]"}})],1)]]})],2)]]})]:_c('quill-editor',{attrs:{"id":"settingValue"},model:{value:(_vm.input.content),callback:function ($$v) {_vm.$set(_vm.input, "content", $$v)},expression:"input.content"}}),(failedRules.hasOwnProperty('required'))?_c('small',{staticClass:"text-danger"},[_vm._v(" Vui lòng nhập ")]):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }