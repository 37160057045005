import { Vue, Component, Watch } from 'vue-property-decorator';
import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BImg
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { SettingViewModel, SettingType, FileParameter } from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import {
  SettingAction,
  SettingGetter,
  settingNamespace
} from '@/store/setting/setting.module-types';
import { dispatchSettingAction } from '@/store/setting/setting.dispatch';
import { settingListDispatcher } from '@/views/setting/settingList/store/setting-list.module';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import { quillEditor } from 'vue-quill-editor';
import { StringFormat } from '@/utility/utils';

@Component({
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    CustomLabel,
    BFormFile,
    BImg,
    quillEditor
  }
})
export default class SettingFormModal extends Vue {
  @settingNamespace.Getter(SettingGetter.setting)
  setting!: SettingViewModel;

  settingValueObject = {};

  input = this.getDefaultInputValue();

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
  };

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  SETTING_TYPE_ENUM = SettingType;
  URL = URL;

  @Watch('setting')
  settingUpdated(): void {
    if (this.setting) {
      this.input = {
        name: this.setting.name as string,
        image: null,
        content: this.setting.settingValue as string,
        type: this.setting.type
      };

      if (this.setting.type === SettingType.JsonObject) {
        this.settingValueObject = [].concat(
          JSON.parse(this.setting.settingValue ?? '')
        );
      }
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  async openEditModal(id: number): Promise<void> {
    dispatchSettingAction(SettingAction.loadSetting, id);
    this.$refs.modal.show();
  }

  getDefaultInputValue() {
    return {
      name: '',
      image: null,
      content: '',
      type: 1
    };
  }

  edit(id: number) {
    const client = new ApiClientFactory().settingClient();

    const imageFile: FileParameter | null = this.input.image
      ? {
          data: this.input.image,
          fileName: (this.input.image as any).name
        }
      : null;

    if (this.input.type === SettingType.JsonObject) {
      this.input.content = JSON.stringify(this.settingValueObject);
    }

    client
      .edit(id, this.input.name, imageFile, this.input.content, this.input.type)
      .then(() => {
        settingListDispatcher.load();
        this.$refs.modal.hide();
        // show toast
        this.$bvToast.toast(
          StringFormat(
            this.$t('UpdateSuccessfully').toString(),
            this.$t('Setting').toString().toLowerCase()
          ),
          {
            title: this.$t('Setting').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          }
        );
      });
  }

  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.setting) {
          this.edit(this.setting.id);
        }
      }
    });
  }

  imageChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.input.image = event.target.files[0];
    }
  }
}
